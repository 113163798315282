import React from "react";

function DropDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M13.28 5.967l-4.346 4.346a1.324 1.324 0 01-1.867 0L2.72 5.967"
      ></path>
    </svg>
  );
}

export default DropDownIcon;
