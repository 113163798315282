import React from "react";

function HorzontalMenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#333539"
        d="M7.999 13.334a2.675 2.675 0 00-2.667 2.667c0 1.466 1.2 2.666 2.667 2.666 1.466 0 2.666-1.2 2.666-2.666 0-1.467-1.2-2.667-2.666-2.667zm16 0a2.675 2.675 0 00-2.667 2.667c0 1.466 1.2 2.666 2.667 2.666 1.466 0 2.666-1.2 2.666-2.666 0-1.467-1.2-2.667-2.666-2.667zm-8 0a2.675 2.675 0 00-2.667 2.667c0 1.466 1.2 2.666 2.667 2.666 1.466 0 2.666-1.2 2.666-2.666 0-1.467-1.2-2.667-2.666-2.667z"
      ></path>
    </svg>
  );
}

export default HorzontalMenuIcon;
