const withName = [
  "DBX (DBX) - our token",
  "XRP (XRP)",
  "Sologenic (SOLO)",
  "XRDOGE (XRdoge)",
  "Coreum (CORE)",
  "Bitstamp (USD)",
  "GateHub (USD)",
  "GateHub (EUR)",
  "SongBird (SGB)",
  "Spark (FLR)",
  "Casinocoin (CSC)",
  "GateHub BTC (BTC)",
  "Equilibrium Games (EQ)",
  "GateHub Fifth (ETH)",
  "Elysian (ELS)",
  "Ripple Fox (CNY)",
  "Bitstamp (BTC)",
  "LOX (LOX)",
  "Vagabond (VGO)",
  "AdvisorBid (ADV)",
  "Treasury (TRSRY)",
  "Xungible (XGBL)",
  "UtiliteX (UtiliteX)",
  "The Reaper (RPR)",
  "StaykX (STX)",
  "Lucretius (LUC)",
  "LedgerCityCoin (LCC)",
];

// const currency = [
// 	"DBX",
// 	"XRP",
// 	"SOLO",
// 	"XRdoge",
// 	"CORE",
// 	"USD",
// 	"USD",
// 	"EUR",
// 	"SGB",
// 	"FLR",
// 	"CSC",
// 	"BTC",
// 	"EQ",
// 	"ETH",
// 	"ELS",
// 	"FoxCNY",
// 	"BTC",
// 	"LOX",
// 	"VGO",
// 	"ADV",
// 	"TRSRY",
// 	"XGBL",
// 	"UtiliteX",
// 	"RPR",
// 	"STX",
// 	"LUC",
// 	"LCC",
// ];

// const currency = ["DBX", "XRP", "ABC", "CRG", "DEF", "JKL", "PQR", "XYZ"];

// const currency = [
//   {
//     currency: "DBX",
//     issuer: "rDBXAAAPcMDfUU8S8GSfydVaUK5cXKnHRt",
//   },
//   {
//     currency: "XRP",
//     issuer: "",
//   },
//   {
//     currency: "SOLO",
//     issuer: "rsoLo2S1kiGeCcn6hCUXVrCpGMWLrRrLZz",
//   },
//   {
//     currency: "USD",
//     issuer: "rhub8VRN55s94qWKDv6jmDy1pUykJzF3wq",
//   },
//   {
//     currency: "ABC",
//     issuer: "r94dyCQktn7mmtYcJHQ7729ahogPFm9JAb",
//   },
//   {
//     currency: "CRG",
//     issuer: "rEvrmyeNrJEST8FAMasecLcZdK2PV7zHEv",
//   },
//   {
//     currency: "DEF",
//     issuer: "rBKK1bqedtcBq8gqJWrzjZZ5S6r61z7h3H",
//   },
//   {
//     currency: "JKL",
//     issuer: "rJvkRmiBUKYKYGatV6uC8dhSvTq5dbcFn3",
//   },
//   {
//     currency: "PQR",
//     issuer: "rsozm9L2mR9EDWnowpg2LSMs7T944znZws",
//   },
//   {
//     currency: "XYZ",
//     issuer: "rEmTHmERnw3sUTGBsXCtEpuWJWcAWRarLk",
//   },
// ];

const currency = [
  {
    currency: "DBX",
    issuer: "rDBXAAAPcMDfUU8S8GSfydVaUK5cXKnHRt",
  },
  {
    currency: "XRP",
    issuer: "",
  },
  {
    currency: "SOLO",
    issuer: "rsoLo2S1kiGeCcn6hCUXVrCpGMWLrRrLZz",
  },

  {
    currency: "CORE",
    issuer: "rcoreNywaoz2ZCQ8Lg2EbSLnGuRBmun6D",
  },
  {
    currency: "XRdoge",
    issuer: "rLqUC2eCPohYvJCEBJ77eCCqVL2uEiczjA",
  },
  {
    currency: "CNY",
    issuer: "rKiCet8SdvWxPXnAgYarFUXMh1zCPz432Y",
  },
  {
    currency: "USD",
    issuer: "rvYAfWj5gh67oV6fW32ZzP3Aw4Eubs59B",
  },
  {
    currency: "USD",
    issuer: "rhub8VRN55s94qWKDv6jmDy1pUykJzF3wq",
  },
  {
    currency: "EUR",
    issuer: "rhub8VRN55s94qWKDv6jmDy1pUykJzF3wq",
  },
  // {
  //   currency: "SGB",
  //   issuer: "",
  // },
  // {
  //   currency: "FLR",
  //   issuer: "",
  // },
  {
    currency: "CSC",
    issuer: "rCSCManTZ8ME9EoLrSHHYKW8PPwWMgkwr",
  },
  {
    currency: "BTC",
    issuer: "rchGBxcD1A1C2tdxF6papQYZ8kjRKMYcL",
  },
  {
    currency: "EQ",
    issuer: "rpakCr61Q92abPXJnVboKENmpKssWyHpwu",
  },
  {
    currency: "ETH",
    issuer: "rcA8X3TVMST1n3CJeAdGk1RdRCHii7N2h",
  },
  {
    currency: "ELS",
    issuer: "rHXuEaRYnnJHbDeuBH5w8yPh5uwNVh5zAg",
  },

  {
    currency: "BTC",
    issuer: "rvYAfWj5gh67oV6fW32ZzP3Aw4Eubs59B",
  },
  {
    currency: "LOX",
    issuer: "rLLJvh6bwj2eTYwzLL484AW6EyH4rdZqWZ",
  },
  {
    currency: "VGO",
    issuer: "rPYJU7Un1ayZ9uHe3QQm8fRiGKZnGYNzhv",
  },
  {
    currency: "ADV",
    issuer: "rPneN8WPHZJaMT9pF4Ynyyq4pZZZSeTuHu",
  },
  {
    currency: "TRSRY",
    issuer: "rLBnhMjV6ifEHYeV4gaS6jPKerZhQddFxW",
  },
  {
    currency: "XGBL",
    issuer: "rMy6sCaDVF1C2BT3qmNG6kgjVDZqZ74uoF",
  },
  {
    currency: "UtiliteX",
    issuer: "rKDsnVfFMzdqrU8Bqno37d29L8ZW3hvrf8",
  },
  {
    currency: "RPR",
    issuer: "r3qWgpz2ry3BhcRJ8JE6rxM8esrfhuKp4R",
  },
  {
    currency: "STX",
    issuer: "rSTAYKxF2K77ZLZ8GoAwTqPGaphAqMyXV",
  },
  {
    currency: "LUC",
    issuer: "rsygE5ynt2iSasscfCCeqaGBGiFKMCAUu7",
  },
  {
    currency: "LCC",
    issuer: "rG9Fo4mgx5DEZp7zKUEchs3R3jSMbx3NhR",
  },
];

// DBX (DBX) - rDBXAAAPcMDfUU8S8GSfydVaUK5cXKnHRt
// XRP (XRP) -
// Sologenic (SOLO) - rsoLo2S1kiGeCcn6hCUXVrCpGMWLrRrLZz
// XRDOGE (XRdoge) - rLqUC2eCPohYvJCEBJ77eCCqVL2uEiczjA
// Coreum (CORE) - rcoreNywaoz2ZCQ8Lg2EbSLnGuRBmun6D
// Bitstamp (USD) - rvYAfWj5gh67oV6fW32ZzP3Aw4Eubs59B
// GateHub (USD) - rhub8VRN55s94qWKDv6jmDy1pUykJzF3wq
// GateHub (EUR) - rhub8VRN55s94qWKDv6jmDy1pUykJzF3wq
// SongBird (SGB) -
// Spark (FLR) -
// Casinocoin (CSC) - rCSCManTZ8ME9EoLrSHHYKW8PPwWMgkwr
// GateHub BTC (BTC) - rchGBxcD1A1C2tdxF6papQYZ8kjRKMYcL
// Equilibrium Games (EQ) - rpakCr61Q92abPXJnVboKENmpKssWyHpwu
// GateHub Fifth (ETH) - rcA8X3TVMST1n3CJeAdGk1RdRCHii7N2h
// Elysian (ELS) - rHXuEaRYnnJHbDeuBH5w8yPh5uwNVh5zAg
// Ripple Fox (CNY) - rKiCet8SdvWxPXnAgYarFUXMh1zCPz432Y
// Bitstamp (BTC) - rvYAfWj5gh67oV6fW32ZzP3Aw4Eubs59B
// LOX (LOX) - rLLJvh6bwj2eTYwzLL484AW6EyH4rdZqWZ
// Vagabond (VGO) - rPYJU7Un1ayZ9uHe3QQm8fRiGKZnGYNzhv
// AdvisorBid (ADV) - rPneN8WPHZJaMT9pF4Ynyyq4pZZZSeTuHu
// Treasury (TRSRY) - rLBnhMjV6ifEHYeV4gaS6jPKerZhQddFxW
// Xungible (XGBL) - rMy6sCaDVF1C2BT3qmNG6kgjVDZqZ74uoF
// UtiliteX (UtiliteX) - rKDsnVfFMzdqrU8Bqno37d29L8ZW3hvrf8
// The Reaper (RPR) - r3qWgpz2ry3BhcRJ8JE6rxM8esrfhuKp4R
// StaykX (STX) - rSTAYKxF2K77ZLZ8GoAwTqPGaphAqMyXV
// Lucretius (LUC) - rsygE5ynt2iSasscfCCeqaGBGiFKMCAUu7
// LedgerCityCoin (LCC) - rG9Fo4mgx5DEZp7zKUEchs3R3jSMbx3NhR

export default currency;
