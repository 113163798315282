import React from "react";

function UnlimitedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="48"
      fill="none"
      viewBox="0 0 49 48"
    >
      <path
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M24.833 42c9.941 0 18-8.059 18-18s-8.059-18-18-18c-9.94 0-18 8.059-18 18s8.06 18 18 18z"
        clipRule="evenodd"
      ></path>
      <path
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16.047 26.928v0a4.142 4.142 0 010-5.858v0a4.142 4.142 0 015.858 0L24.833 24l-2.928 2.928a4.142 4.142 0 01-5.858 0zM33.62 21.072v0a4.142 4.142 0 010 5.858v0a4.142 4.142 0 01-5.859 0L24.833 24l2.928-2.928a4.142 4.142 0 015.858 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default UnlimitedIcon;
