import React from "react";

function ExchangeArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="24"
      fill="none"
      viewBox="0 0 22 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M2 18l4 4m-4-4h18H2zm0 0l4-4-4 4zM20 6l-4 4m4-4H2h18zm0 0l-4-4 4 4z"
      ></path>
    </svg>
  );
}

export default ExchangeArrowIcon;
