import React from "react";

function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
    >
      <path d="M9 7H6a2 2 0 00-2 2v9a2 2 0 002 2h9a2 2 0 002-2v-3"></path>
      <path d="M9 14.996h3l8.5-8.5a2.121 2.121 0 00-3-3l-8.5 8.5v3zM16 5l3 3"></path>
    </svg>
  );
}

export default EditIcon;
