import React from "react";

function SunIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="24"
      fill="none"
      viewBox="0 0 26 24"
    >
      <path
        fill="#333539"
        d="M12.085 1.5h1.61v3.75h-1.61V1.5zM17.467 6.675l2.822-2.63 1.138 1.061-2.822 2.63-1.138-1.06zM20.134 11.25h4.024v1.5h-4.024v-1.5zM17.467 17.325l1.138-1.06 2.822 2.63-1.138 1.06-2.822-2.63zM12.085 18.75h1.61v3.75h-1.61v-3.75zM4.352 18.894l2.822-2.63 1.138 1.062-2.822 2.63-1.138-1.062zM1.62 11.25h4.025v1.5H1.621v-1.5zM4.352 5.106L5.49 4.045l2.822 2.63-1.138 1.06-2.822-2.629zM12.89 9c.636 0 1.259.176 1.788.506.53.33.942.798 1.186 1.346a2.81 2.81 0 01.183 1.733 2.943 2.943 0 01-.881 1.536 3.3 3.3 0 01-1.649.821 3.44 3.44 0 01-1.86-.17 3.18 3.18 0 01-1.445-1.105A2.856 2.856 0 019.67 12c0-.795.34-1.558.944-2.12A3.347 3.347 0 0112.889 9zm0-1.5c-.956 0-1.89.264-2.684.758a4.585 4.585 0 00-1.779 2.02 4.216 4.216 0 00-.274 2.6 4.414 4.414 0 001.321 2.304 4.948 4.948 0 002.473 1.231 5.158 5.158 0 002.79-.256 4.773 4.773 0 002.168-1.657c.53-.74.814-1.61.814-2.5 0-1.194-.509-2.338-1.415-3.182C15.4 7.974 14.17 7.5 12.89 7.5z"
      ></path>
    </svg>
  );
}

export default SunIcon;
