import React from "react";

function TelegramIcon() {
  return (
    <a href="https://t.me/DigitalBlockEx" target="_blank"><svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 24 20"
    >
      <path
        fill="#fff"
        d="M2.324 8.827s10-4.104 13.468-5.55C17.122 2.7 21.63.85 21.63.85s2.08-.81 1.907 1.156c-.057.809-.52 3.641-.982 6.705-.694 4.335-1.445 9.075-1.445 9.075s-.116 1.33-1.099 1.56c-.982.232-2.6-.808-2.89-1.04-.231-.173-4.335-2.774-5.838-4.046-.404-.347-.867-1.04.058-1.85 2.08-1.907 4.566-4.277 6.07-5.78.693-.694 1.386-2.312-1.504-.347-4.104 2.833-8.15 5.491-8.15 5.491s-.925.579-2.659.058c-1.734-.52-3.757-1.214-3.757-1.214s-1.387-.867.983-1.791z"
      ></path>
    </svg></a>
  );
}

export default TelegramIcon;
