import React from "react";

function TimeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="48"
      fill="none"
      viewBox="0 0 49 48"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M24.606 15.542v8.912l7.005 4.27M8.914 17.487a17.231 17.231 0 1120.188 22.52"
      ></path>
      <path
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M20.498 42.007H7.493a1 1 0 01-1-1v-.812a4.202 4.202 0 014.19-4.19h6.626a4.202 4.202 0 014.19 4.19v.812a1 1 0 01-1 1zM13.996 31.002a4.002 4.002 0 110-8.003 4.002 4.002 0 010 8.003z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default TimeIcon;
