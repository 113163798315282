import React from "react";

function FixedPriceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="48"
      fill="none"
      viewBox="0 0 49 48"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M23.947 24.22l1.1-1.1M17.307 30.86l-1.1 1.1M16.193 28.142c-.182.754-.018 1.566.572 2.156l.552.552.658.658a2.192 2.192 0 003.43-2.676l-1.55-2.588a2.192 2.192 0 013.43-2.676l.658.658.552.552c.588.588.752 1.398.574 2.15M31.781 16.386a.742.742 0 11-1.05 1.049.742.742 0 011.05-1.05"
      ></path>
      <path
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M27.19 8h8.77a4.204 4.204 0 014.207 4.204v8.77a4.21 4.21 0 01-1.232 2.974l-16.82 16.82a4.204 4.204 0 01-5.946 0l-8.77-8.77a4.204 4.204 0 010-5.946l16.82-16.82A4.206 4.206 0 0127.19 8z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default FixedPriceIcon;
