import React from "react";

function ArrowDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="17"
      fill="none"
      viewBox="0 0 19 17"
    >
      <path
        stroke="#3F444D"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="4.5"
        d="M15.11 6.34l-4.618 4.618a1.406 1.406 0 01-1.984 0L3.89 6.34"
      ></path>
    </svg>
  );
}

export default ArrowDownIcon;
