import React from "react";

function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="28"
      fill="none"
      viewBox="0 0 25 28"
    >
      <g clipPath="url(#clip0_2_13151)">
        <path
          fill="#8F8F8F"
          d="M10.843 22.29c-5.289 0-9.595-4.748-9.595-10.58S5.554 1.13 10.843 1.13c5.289 0 9.595 4.748 9.595 10.58 0 5.831-4.306 10.58-9.595 10.58zm0-19.612c-4.521 0-8.19 4.056-8.19 9.032 0 4.975 3.669 9.031 8.19 9.031 4.521 0 8.19-4.056 8.19-9.031 0-4.976-3.669-9.032-8.19-9.032z"
        ></path>
        <path
          stroke="#8F8F8F"
          strokeWidth="3"
          d="M17.59 19.164L29.41 30.139"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2_13151">
          <path fill="#fff" d="M0 0H24.525V27.11H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SearchIcon;
