import React from "react";

function TwitterIcon() {
  return (
    <a href="https://twitter.com/DigitalBlockEx" target="_blank">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 24 20">
        <path
          fill="#fff"
          d="M7.548 20c9.057 0 14.01-7.694 14.01-14.366 0-.218-.005-.436-.014-.652.963-.715 1.795-1.6 2.456-2.615a9.627 9.627 0 01-2.828.795A5.047 5.047 0 0023.338.37a9.73 9.73 0 01-3.127 1.225A4.863 4.863 0 0016.617 0c-2.72 0-4.925 2.261-4.925 5.048 0 .396.044.782.128 1.151C7.728 5.99 4.1 3.98 1.67.924a5.134 5.134 0 00-.667 2.538 5.08 5.08 0 002.192 4.203 4.796 4.796 0 01-2.23-.632l-.001.065c0 2.445 1.697 4.486 3.95 4.949a4.813 4.813 0 01-2.224.087c.627 2.006 2.445 3.466 4.6 3.506A9.724 9.724 0 010 17.732 13.69 13.69 0 007.548 20"></path>
      </svg>
    </a>
  );
}

export default TwitterIcon;
