import React from "react";

function MenuIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5 5h8.25M5 8h8.25M5 11h8.25M2.75 5v2.25M2.75 8.75V11"
      ></path>
    </svg>
  );
}

export default MenuIcon2;
