import React from "react";

function HeartFillIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 23 21"
    >
      <path
        fill="#F01E01"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.146 19.843c-.354.125-.938.125-1.292 0-3.02-1.03-9.77-5.333-9.77-12.625 0-3.218 2.593-5.822 5.791-5.822 1.896 0 3.573.916 4.625 2.333a5.76 5.76 0 014.625-2.333c3.198 0 5.792 2.604 5.792 5.822 0 7.292-6.75 11.594-9.771 12.625z"
      ></path>
    </svg>
  );
}

export default HeartFillIcon;
