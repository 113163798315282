import DropDownIcon from "./DropDownIcon";
import SearchIcon from "./SearchIcon";
import AllNftIcon from "./AllNftIcon";
import ArtNftIcon from "./ArtNftIcon";
import MusicNftIcon from "./MusicNftIcon";
import MotionNftIcon from "./MotionNftIcon";
import SportIcon from "./SportsNftIcon";
import CardsIcon from "./CardsIcon";
import CollectiblesIcon from "./CollectiblesIcon";
import OthersIcon from "./OthersIcon";
import HorzontalMenuIcon from "./HorzontalMenuIcon";
import RoundCrossIcon from "./RoundCrossIcon";
import HeartIcon from "./HeartIcon";
import FireIcon from "./FireIcon";
import TelegramIcon from "./TelegramIcon";
import TwitterIcon from "./TwitterIcon";
import CrossIcon from "./CrossIcon";
import ArrowBackIcon from "./ArrowBackIcon";
import ArrowDownIcon from "./ArrowDownIcon";
import CopyIcon from "./CopyIcon";
import MenuIcon from "./MenuIcon";
import CryptoIcon from "./CryptoIcon";
import HeartFillIcon from "./HeartFillIocn";
import FixedPriceIcon from "./FixedPriceIcon";
import TimeIcon from "./TimeIcon";
import UnlimitedIcon from "./UnlimitedIcon";
import PlusIcon from "./PlusIcon";
import ExchangeIcon from "./ExchangeIcon";
import SunIcon from "./SunIcon";
import MenuIcon2 from "./MenuIcon2";
import TokenIcon from "./TokenIcon";
import ExchangeArrowIcon from "./ExchangeArrowIcon";
import HomeIcon from "./HomeIcon";
import EditIcon from "./EditIcon";
import InstagramIcon from "./InstagramIcon";
import LanguageIcon from "./LanguageIcon";

export const SortIcon = props => (
  <svg width={15} height={12} viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.79904 6.66698L7.79904 10.0003L11.799 6.66698H3.79904Z" fill="#333539" />
    <path d="M11.7989 5.333L7.79889 1.99966L3.79889 5.333L11.7989 5.333Z" fill="#333539" />
  </svg>
);

export {
  HomeIcon,
  InstagramIcon,
  LanguageIcon,
  EditIcon,
  ExchangeArrowIcon,
  TokenIcon,
  MenuIcon2,
  ExchangeIcon,
  PlusIcon,
  UnlimitedIcon,
  TimeIcon,
  FixedPriceIcon,
  MenuIcon,
  CryptoIcon,
  CopyIcon,
  DropDownIcon,
  SearchIcon,
  AllNftIcon,
  ArtNftIcon,
  MusicNftIcon,
  MotionNftIcon,
  SportIcon,
  CardsIcon,
  CollectiblesIcon,
  OthersIcon,
  HorzontalMenuIcon,
  RoundCrossIcon,
  HeartIcon,
  HeartFillIcon,
  FireIcon,
  TelegramIcon,
  TwitterIcon,
  CrossIcon,
  ArrowBackIcon,
  ArrowDownIcon,
  SunIcon,
};
