import React, { useEffect } from "react";
import "./style.scss";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="privacy-policy-page flex">
      <div class="wrap wrapWidth flex justify-center">
        <div className="page_block flex flex-col">
          <div className="form_block flex flex-col">
            <img src="./DBX-horizontal1.svg" className="logo" />
            <h1 className="title mb-8">Privacy Policy</h1>

            <div className="paragraph flex flex-col">
              <p className="paragraph-desc">
                Digital Block Exchange ("us", "we", or "our") operates the www.DigitalBlock.Exchange website and the related applications
                (collectively referred as "Services"). We have prepared this Privacy Policy to demonstrate that our policies and practices
                regarding the collection, use, and disclosure of personal data when you use our Services and the choices you have associated
                with that data.
              </p>
              <br />
              <p className="paragraph-desc">
                By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise
                defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
              </p>
              <p className="paragraph-desc">1.Information We Collected</p>
              <p className="paragraph-desc">
                We collect several different types of information for various purposes to provide and improve our Services to you.
              </p>
              <br />
              <p className="paragraph-desc">1.1 Personal Data</p>
              <p className="paragraph-desc">
                While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to
                contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
              </p>
              <p className="paragraph-desc">Your personal profile picture;</p>
              <p className="paragraph-desc">Email address;</p>
              <p className="paragraph-desc">First name and last name;</p>
              <p className="paragraph-desc">Social media accounts;</p>
              <br />
              <p className="paragraph-desc">1.2 Your Device Data</p>
              <p className="paragraph-desc">
                If you access our Services through a mobile device, we may be able to collect certain information related to your device,
                including but not limited to: unique identifiers (Android ID, mobile advertising identifier ,etc), the general location of
                your mobile device (not precise geolocation data), your mobile device’s brand, model, operating system, resolution, screen
                size, system version, Bluetooth settings, internet connection, random-access memory (“RAM”), the application you have
                installed, the applications you have running in the background.
              </p>
              <br />
              <p className="paragraph-desc">1.3 Your Usage Data</p>
              <p className="paragraph-desc">
                We may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information
                such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service
                that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other
                diagnostic data.
              </p>
              <br />
              <p className="paragraph-desc">1.4 Tracking & Cookies Data</p>
              <p className="paragraph-desc">
                We use cookies and other similar tracking technologies to track the activity on our Service and hold certain information.
                Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your
                browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect
                and track information and to improve and analyze our Service.
              </p>
              <br />
              <p className="paragraph-desc">2. How We Use Your Data</p>
              <p className="paragraph-desc">
                We use the collected data for various purposes, including but not limited to the following. If we use your personal
                information in any other ways, we will disclose this to you. If you choose to limit the ways we can use your personal
                information, some or all of the Services may not be available to you.
              </p>
              <p className="paragraph-desc">To provide and maintain the Services;</p>
              <p className="paragraph-desc">To verify user identity and prevent fraud or unauthorized access;</p>
              <p className="paragraph-desc">To notify you about changes to our Services；</p>
              <p className="paragraph-desc">
                To allow you to participate in interactive features of our Services when you choose to do so;
              </p>
              <p className="paragraph-desc">To provide customer contact and support Services;</p>
              <p className="paragraph-desc">To provide analysis or valuable information so that we can improve the Services;</p>
              <p className="paragraph-desc">To monitor the usage of the Services;</p>
              <p className="paragraph-desc">To detect, prevent and address technical issues;</p>
              <p className="paragraph-desc">To deliver you certain material relating to our Service;</p>
              <br />
              <p className="paragraph-desc">3. Transfer Of Data</p>
              <p className="paragraph-desc">
                Your information, including Personal Data, may be transferred to — and maintained on — computers or cloud service center
                located outside of your country or other governmental jurisdiction where the data protection laws may differ than those from
                your jurisdiction.
              </p>
              <br />
              <p className="paragraph-desc">
                Your consent to this Privacy Policy followed by your submission of such information represents your consent to that
                transfer.
              </p>
              <br />
              <p className="paragraph-desc">4. Disclosure Of Data</p>
              <p className="paragraph-desc">
                We may disclose and share your personal data with third-party under the following circumstances:
              </p>
              <br />
              <p className="paragraph-desc">4.1 Legal Requirements</p>
              <p className="paragraph-desc">
                We may disclose your Personal Data in the good faith belief that such action is necessary to:
              </p>
              <p className="paragraph-desc">To comply with a legal obligation;</p>
              <p className="paragraph-desc">To protect and defend the rights or property of Digital Block Exchange;</p>
              <p className="paragraph-desc">To prevent or investigate possible wrongdoing in connection with the Service;</p>
              <p className="paragraph-desc">To protect the personal safety of users of the Service or the public;</p>
              <p className="paragraph-desc">To protect against legal liability.</p>
              <br />
              <p className="paragraph-desc">4.2 With your consent</p>
              <p className="paragraph-desc">
                With your consent, we may share your personal data with third party for the purpose as prescribed in the consent notice.
              </p>
              <br />
              <p className="paragraph-desc">4.3 Employees, Third-Party Processors and Third-Party Service Providers</p>
              <p className="paragraph-desc">
                We disclose your personal information to our employees, contractors, affiliates, distributors, dealers, vendors and
                suppliers who provide certain services to us or on our behalf, such as operating and supporting the Services, analyzing
                data, or performing marketing or consulting services. These Service Providers will only have access to the personal
                information needed to perform these limited functions on our behalf.
              </p>
              <br />
              <p className="paragraph-desc">4.4 Business Transfers or Bankruptcy</p>
              <p className="paragraph-desc">
                In the event of a merger, acquisition, bankruptcy or other sale of all or a portion of our assets, any personal information
                owned or controlled by us may be one of the assets transferred to third parties. We will notify you via email or a prominent
                notice within our Services of any change in ownership or uses of your personal information, as well as any choices you may
                have regarding your personal information.
              </p>
              <br />
              <p className="paragraph-desc">5. Security Of Data</p>
              <p className="paragraph-desc">
                The security of your data is important to us, but remember that no method of transmission over the Internet, or method of
                electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </p>
              <br />
              <p className="paragraph-desc">6. Third-party Service Providers</p>
              <p className="paragraph-desc">
                We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service
                on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used. These third parties
                have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for
                any other purpose. We may use third-party Service Providers to monitor and analyze the use of our Service.
              </p>
              <br />
              <p className="paragraph-desc">
                No mobile information will be shared with third parties/affiliates for marketing/promotional purposes.
              </p>
              <br />
              <p className="paragraph-desc">
                All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with
                any third parties.
              </p>
              <br />
              <p className="paragraph-desc">7. Links To Other Sites</p>
              <p className="paragraph-desc">
                Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be
                directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
              </p>
              <br />
              <p className="paragraph-desc">
                We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites
                or services.
              </p>
              <br />
              <p className="paragraph-desc">
                8. Information for users residing in the European Economic Area, United Kingdom and Switzerland (collectively referred as
                “Designated Countries”)
              </p>
              <p className="paragraph-desc">
                This article only applies to all users residing in the Designated Countries at the time of data collection. We may ask you
                which specific country you come from while you use some of our service, or we may also reply your IP address or other
                similar technology to identify your country. Where we rely IP address, we can’t apply this article to any user who
                masquerade his/her IP so as to not appear in the Designated Countries.
              </p>
              <br />
              <p className="paragraph-desc">8.1 Our Relationship to You</p>
              <p className="paragraph-desc">
                Digital Block Exchange is a data controller with regard to any personal information collected from users of its Services. A
                “user” is an individual providing personal information to us via our Service, such as by creating an account with our
                Services, signing up for our website, or otherwise accessing or using our Services. A “data controller” is an entity that
                determines the purposes for which and the manner in which any personal information is processed. Any third parties that act
                as our service providers are “data processors” that handle your personal information in accordance with our instructions.
              </p>
              <br />
              <p className="paragraph-desc">8.2 Legal Basis for Processing Personal Information</p>
              <p className="paragraph-desc">
                The legal basis on which we rely on to process your personal information include: your consent to the processing;
                satisfaction of our legal obligations; necessity to protect your vital interests; necessity to carry out our obligations
                arising from any contracts we entered with you or to take steps at your request prior to entering into a contract with you;
                necessity to process in the public interest; or for our legitimate interests as described in those sections.
              </p>
              <br />
              <p className="paragraph-desc">8.3 Marketing Activities</p>
              <p className="paragraph-desc">
                If you are a current user of our Service, we will only contact you by electronic means (such as email or SMS) per your
                communication preferences and/or with information about our Service that are similar to the Services you previously
                purchased from us or negotiated to purchase from us.
              </p>
              <br />
              <p className="paragraph-desc">
                For new users located in the Designated Countries, we will contact you by electronic means for marketing purposes only based
                on your consent or based on your friends’ consent. You can always withdraw your consent or change your marketing
                communication preferences at any time and free of charge. To opt out of the emails, please click the “unsubscribe” link in
                the footer of marketing emails or contact us at admin@DigitalBlock.Exchange. Marketing communications are promotional in
                nature and do not include transactional or Service-related communications.
              </p>
              <p className="paragraph-desc">8.4 Individual Rights Under EEA’s</p>
              <p className="paragraph-desc">
                We provide you with the following rights when you use our Service. Please contact us at admin@DigitalBlock.Exchange if you
                would like to exercise your rights under applicable law. When we receive an individual rights request from you, please make
                sure you are ready to verify your identity. Please be advised that there are limitations to your individual rights. We may
                limit your individual rights in the following ways:
              </p>
              <p className="paragraph-desc">Where denial of access is required or authorized by law;</p>
              <p className="paragraph-desc">When granting access would have a negative impact on other's privacy;</p>
              <p className="paragraph-desc">To protect our rights and properties;</p>
              <p className="paragraph-desc">Where the request is frivolous or burdensome.</p>
              <p className="paragraph-desc">Right to withdraw consent.</p>
              <p className="paragraph-desc">
                If we rely on consent to process your personal information, you have the right to withdraw your consent at any time. A
                withdrawal of consent will not affect the lawfulness of our processing or the processing of any third parties based on
                consent before your withdrawal.
              </p>
              <br />
              <p className="paragraph-desc">Right to erasure (the “Right to be Forgotten”).</p>
              <p className="paragraph-desc">
                You may request us to erase any of your personal information held by us that: is no longer necessary in relation to the
                purposes for which it was collected or otherwise processed; was collected in relation to processing that you previously
                consented to, but later withdrew such consent; or was collected in relation to processing activities to which you object,
                and there are no overriding legitimate grounds for our processing.
              </p>
              <br />
              <p className="paragraph-desc">Right to restriction.</p>
              <p className="paragraph-desc">
                You have the right to restrict our processing your personal information where one of the following applies:
              </p>
              <br />
              <p className="paragraph-desc">
                You contest the accuracy of your personal information that we processed. We will restrict the processing of your personal
                information, which may result in an interruption of some or all of the Services, during the period necessary for us to
                verify the accuracy of your personal information;
              </p>
              <p className="paragraph-desc">
                The processing is unlawful and you oppose the erasure of your personal information and request the restriction of its use
                instead;
              </p>
              <p className="paragraph-desc">
                TWe no longer need your personal information for the purposes of the processing, but it is required by you to establish,
                exercise or defense of legal claims;
              </p>
              <p className="paragraph-desc">
                You have objected to processing, pending the verification whether the legitimate grounds of our processing override your
                rights.
              </p>
              <br />
              <p className="paragraph-desc">
                We will only process your restricted personal information shall with your consent or for the establishment, exercise or
                defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of important
                public interest. We will inform you if or when the restriction is lifted.
              </p>
              <br />
              <p className="paragraph-desc">Right to object to processing.</p>
              <p className="paragraph-desc">
                You may object to our processing at any time and as permitted by applicable law if we process your personal information on
                the legal basis of consent, contract or legitimate interests. We can continue to process your personal information if it is
                necessary for the defense of legal claims, or for any other exceptions permitted by applicable law.
              </p>
              <br />
              <p className="paragraph-desc">Right to data portability.</p>
              <p className="paragraph-desc">
                If we process your personal information based on a contract with you or based on your consent, or the processing is carried
                out by automated means, you may request to receive your personal information in a structured, commonly used and
                machine-readable format, and to have us transfer your personal information directly to another “controller”, where
                technically feasible, unless exercise of this right adversely affects the rights and freedoms of others.
              </p>
              <br />
              <p className="paragraph-desc">Notification to third-parties.</p>
              <p className="paragraph-desc">
                If we share your personal information with third parties, we will notify them of any requests for rectification, erasure or
                restriction of your personal information, unless this proves impossible or involves disproportionate effort. Upon your
                request, we will identify such third parties.
              </p>
              <br />
              <p className="paragraph-desc">Automated individual decision-making, including profiling.</p>
              <p className="paragraph-desc">
                You have the right not to be subject to a decision based solely on automated processing of your personal information,
                including profiling, which produces legal or similarly significant effects on you, unless an exception applies under
                applicable law.
              </p>
              <br />
              <p className="paragraph-desc">Right to Lodge a Complaint.</p>
              <p className="paragraph-desc">
                If you believe we have infringed or violated your privacy rights, please contact us at admin@DigitalBlock.Exchange so that
                we can work to resolve your concerns. You also have a right to lodge a complaint with a competent supervisory authority
                situated in a Member State of your habitual residence, place of work, or place of alleged infringement.
              </p>
              <br />
              <p className="paragraph-desc">8.5 DPO</p>
              <p className="paragraph-desc">
                We have appointed a local coordinator for privacy/Data Protection Officer (DPO). To get in touch with him/her for any
                information regarding the Personal Data, please contact: admin@DigitalBlock.Exchange.
              </p>
              <br />
              <p className="paragraph-desc">9. Children's Privacy</p>
              <p className="paragraph-desc">
                Our Service does not address anyone under the age of 13 ("Children"). We do not knowingly collect personally identifiable
                information from anyone under the age of 13. If you are a parent or guardian and you are aware that your Children has
                provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children
                without verification of parental consent, we take steps to remove that information from our servers.
              </p>
              <br />
              <p className="paragraph-desc">10. Special Provisions for California Users</p>
              <p className="paragraph-desc">
                California law allows any of its resident to file a request and receive a notice from us, once a year and free of charge,
                demonstrating what personal information we have shared with our third party partners for direct marketing activities. If you
                habitually reside in California and would like request this information, you can contact us by sending us an email, which
                should specify “California Privacy Right Notice”. In general, we would respond your request in 30 days.
              </p>
              <br />
              <p className="paragraph-desc">11. Changes To This Privacy Policy</p>
              <p className="paragraph-desc">
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on
                this page. We will let you know via email, pop-up notice, and/or any other similar prominent way on our Service, prior to
                the change becoming effective and update the "effective date" at the top of this Privacy Policy.
              </p>
              <br />
              <p className="paragraph-desc">
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective
                when they are posted on this page.
              </p>
              <br />
              <p className="paragraph-desc">12. Contact Us</p>
              <p className="paragraph-desc">If you have any questions about this Privacy Policy, please contact us:</p>
              <p className="paragraph-desc">By email:admin@DigitalBlock.Exchange</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
