import React from "react";

function ExchangeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12px"
      height="12px"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke="#333539"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M8.24 6.749l-1.252 1.25M4 6.749h4.239M4 5.251L5.25 4M8.239 5.251h-4.24"
      ></path>
      <circle cx="6" cy="6" r="4.5" stroke="#333539"></circle>
    </svg>
  );
}

export default ExchangeIcon;
