import React from "react";

function InstagramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M7.64.078C9.027.014 9.47 0 13 0c3.531 0 3.973.015 5.358.078 1.386.063 2.33.284 3.158.604.867.327 1.652.839 2.302 1.5a6.35 6.35 0 011.499 2.302c.321.827.541 1.773.605 3.155C25.986 9.028 26 9.47 26 13c0 3.531-.015 3.973-.078 5.36-.063 1.382-.284 2.328-.605 3.155a6.378 6.378 0 01-1.499 2.303 6.375 6.375 0 01-2.302 1.499c-.827.321-1.772.541-3.155.605-1.388.064-1.83.078-5.361.078-3.531 0-3.973-.015-5.36-.078-1.382-.063-2.328-.284-3.155-.605a6.378 6.378 0 01-2.303-1.499 6.372 6.372 0 01-1.5-2.302c-.32-.827-.54-1.772-.604-3.155C.014 16.972 0 16.53 0 13c0-3.531.015-3.973.078-5.358.063-1.385.284-2.33.604-3.158a6.377 6.377 0 011.5-2.302 6.372 6.372 0 012.302-1.5C5.31.362 6.257.142 7.639.078h.001zm10.613 2.34c-1.37-.063-1.782-.076-5.253-.076s-3.882.013-5.253.076c-1.268.058-1.956.27-2.415.448a4.037 4.037 0 00-1.495.971c-.431.42-.763.93-.971 1.495-.179.459-.39 1.147-.448 2.415-.063 1.37-.076 1.782-.076 5.253s.013 3.882.076 5.253c.058 1.268.27 1.956.448 2.415.208.563.54 1.075.971 1.495.42.431.932.763 1.495.971.459.178 1.147.39 2.415.448 1.37.063 1.78.076 5.253.076 3.472 0 3.882-.013 5.253-.076 1.268-.058 1.956-.27 2.415-.448a4.037 4.037 0 001.495-.971c.431-.42.763-.932.971-1.495.178-.459.39-1.147.448-2.415.063-1.37.076-1.782.076-5.253s-.013-3.882-.076-5.253c-.058-1.268-.27-1.956-.448-2.415a4.037 4.037 0 00-.971-1.495 4.033 4.033 0 00-1.495-.971c-.459-.179-1.147-.39-2.415-.448zm-6.914 14.59a4.335 4.335 0 004.728-7.075 4.337 4.337 0 10-4.728 7.075zM8.275 8.274a6.682 6.682 0 119.45 9.45 6.682 6.682 0 01-9.45-9.45zm12.889-.962A1.579 1.579 0 1018.93 5.08a1.58 1.58 0 002.234 2.234z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default InstagramIcon;
