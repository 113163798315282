import React from "react";

function LanguageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <mask
        id="mask0_813_5737"
        style={{ maskType: "alpha" }}
        width="26"
        height="26"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0H26V26H0z"></path>
      </mask>
      <g mask="url(#mask0_813_5737)">
        <path
          fill="#1C1B1F"
          d="M13 23.833c-1.48 0-2.88-.284-4.198-.853a10.97 10.97 0 01-3.452-2.33 10.97 10.97 0 01-2.33-3.452A10.477 10.477 0 012.168 13c0-1.499.285-2.902.854-4.211a11.01 11.01 0 012.329-3.44 10.985 10.985 0 013.452-2.33A10.488 10.488 0 0113 2.166c1.499 0 2.903.285 4.211.853 1.31.57 2.456 1.346 3.44 2.33s1.76 2.13 2.329 3.44c.57 1.309.854 2.712.854 4.21 0 1.481-.285 2.88-.854 4.199a10.97 10.97 0 01-2.33 3.452 11.01 11.01 0 01-3.439 2.33c-1.308.569-2.712.853-4.21.853zm0-2.22c.47-.65.876-1.328 1.22-2.032.342-.704.622-1.453.839-2.248h-4.117c.217.795.496 1.544.84 2.248.343.704.749 1.381 1.218 2.031zm-2.816-.434a14.934 14.934 0 01-.853-1.856 15.827 15.827 0 01-.61-1.99H5.525a8.995 8.995 0 001.963 2.356 7.8 7.8 0 002.696 1.49zm5.633 0a7.788 7.788 0 002.694-1.49 8.984 8.984 0 001.964-2.356H17.28a15.96 15.96 0 01-.608 1.99c-.244.642-.529 1.26-.854 1.856zM4.604 15.167h3.684A14.484 14.484 0 018.126 13a14.481 14.481 0 01.162-2.167H4.604A8.678 8.678 0 004.334 13a8.68 8.68 0 00.27 2.166zm5.85 0h5.092c.054-.362.095-.718.123-1.07.026-.353.04-.718.04-1.097a14.491 14.491 0 00-.163-2.167h-5.091A14.684 14.684 0 0010.291 13a14.076 14.076 0 00.162 2.166zm7.259 0h3.683a8.676 8.676 0 00.27-2.167 8.674 8.674 0 00-.27-2.167h-3.683A14.74 14.74 0 0117.875 13a14.126 14.126 0 01-.162 2.166zm-.434-6.5h3.196a8.984 8.984 0 00-1.964-2.357 7.789 7.789 0 00-2.694-1.49 15.1 15.1 0 01.854 1.855c.243.642.446 1.305.608 1.991zm-6.337 0h4.117a12.83 12.83 0 00-.84-2.248A13.753 13.753 0 0013 4.387c-.47.65-.875 1.327-1.218 2.032a12.822 12.822 0 00-.84 2.247zm-5.417 0h3.196c.163-.687.366-1.35.61-1.992.243-.64.528-1.258.853-1.854a7.8 7.8 0 00-2.696 1.49 8.995 8.995 0 00-1.963 2.355z"
        ></path>
      </g>
    </svg>
  );
}

export default LanguageIcon;
