import React from "react";

function HeartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 35 35"
    >
      <rect width="35" height="35" rx="8"></rect>
      <path
        stroke="#8F8F8F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18.146 29.844c-.354.125-.938.125-1.292 0-3.02-1.032-9.77-5.334-9.77-12.625 0-3.219 2.593-5.823 5.791-5.823 1.896 0 3.573.916 4.625 2.333a5.76 5.76 0 014.625-2.333c3.198 0 5.792 2.604 5.792 5.823 0 7.291-6.75 11.593-9.771 12.625z"
      ></path>
    </svg>
  );
}

export default HeartIcon;
