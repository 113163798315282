import React from "react";

function FireIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      fill="none"
      viewBox="0 0 17 21"
    >
      <g clipPath="url(#clip0_2_13031)">
        <path
          fill="#F01E01"
          d="M1.309 7.027c.449.73.912 1.634 1.268 2.7.234.7.385 1.353.478 1.935.8-.7 1.756-1.72 2.449-3.083 1.81-3.553.531-6.864.063-7.92 1.058.396 2.702 1.158 4.131 2.55 2.81 2.727 2.756 5.886 2.703 6.773.366-.332.824-.821 1.21-1.478a5.35 5.35 0 00.667-1.86c3.127 3.533 3.488 7.84.986 10.725-.268.309-3.146 3.518-7.663 3.26-3.039-.172-4.931-1.81-5.244-2.088C.665 17.034.08 15.002.323 13.371c.215-1.435.98-1.966 1.147-3.925a8.272 8.272 0 00-.161-2.42z"
        ></path>
        <path
          fill="#F35B00"
          d="M8.108 4.686c.098 1.696-.322 2.916-.697 3.693-1 2.06-2.551 2.764-3.463 5.042a7.641 7.641 0 00-.542 2.521 3.737 3.737 0 00-.507-.918 4.427 4.427 0 00-.985-.942c-.059.785-.054 2.56 1.273 4.229.39.488 1.029 1.274 2.287 1.81.771.328 1.493.441 1.971.484 1.488 0 2.688-.203 3.02-.281 1.36-.309 2.223-1.657 2.73-2.455 1.493-2.333 1.571-4.706 1.43-6.207a8.884 8.884 0 01-.922 2.064c-.37.61-.77 1.118-1.146 1.528a7.706 7.706 0 00-.761-1.962c-.663-1.203-1.18-1.41-1.78-2.446-.498-.86-.4-1.161-.888-3.006a32.67 32.67 0 00-1.02-3.154z"
        ></path>
        <path
          fill="#FDB800"
          d="M5.406 13.117c-.01.394-.049.984-.224 1.68-.283 1.153-.61 1.333-.761 2.138-.088.465-.38 1.982.76 2.928.854.707 2.079.77 2.732.79.542.015 2 .034 3.02-.689 1.619-1.149 1.287-3.639.35-5.143-.58-.934-1.111-1.04-1.809-2.24a6.827 6.827 0 01-.888-2.65 5.608 5.608 0 00-.444 1.86c-.097 1.587.566 2.37.064 3.463-.166.36-.498.84-1.24 1.325a4.033 4.033 0 00-.223-1.504c-.332-.95-.947-1.607-1.337-1.958z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2_13031">
          <path
            fill="#fff"
            d="M0 0H16.643V20H0z"
            transform="translate(.27 .66)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default FireIcon;
