import React from "react";

function ExchangeGraph() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 959 287"
    >
      <path
        fill="url(#paint0_linear_432_6090)"
        d="M899.062 18.407C925.299 19.107 949.953 6.427 959 0l-.295 286.952H0c22.618-31.905 70.116-78.73 97.257-76.591 66.723 5.259 42.974-20.159 71.247-18.845 62.199 11.833 61.068-52.59 101.78-51.713 50.891 7.888 35.058-14.901 67.854-17.092l59.938-.439c30.534-4.382 24.879-8.765 46.366-8.765 9.952.702 26.765-2.921 33.927-4.82 24.427-6.662 70.493-9.204 90.472-9.642 54.283.438 32.796-15.777 81.424-16.215 89.341-12.71 48.629-34.622 135.708-46.455 66.723 1.315 46.367-12.71 78.032-16.654 12.666-.35 28.649-1.022 35.057-1.314z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_432_6090"
          x1="1133.74"
          x2="1026.88"
          y1="-187.572"
          y2="658.337"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7D63F"></stop>
          <stop offset="0.505" stopColor="#39B54A"></stop>
          <stop offset="1" stopColor="#A3DE02"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ExchangeGraph;
